import 'animate.css';
import 'application/javascripts/global';
import 'application/javascripts/office';
import 'application/javascripts/works';
import 'application/stylesheets';
import 'shared/images/favicon.png';
import 'shared/images/tolo_arrow_collapsed.svg';
import 'shared/images/tolo_dot.svg';
import 'shared/images/tolo_logotype.svg';

require("turbolinks").start()
