document.addEventListener('turbolinks:load', () => {
  const worksFilterList = document.getElementById('works-tags-filter')

  if (worksFilterList) {
    const header = document.getElementById('works-tags-filter--header')
    const arrow = document.getElementById('works-tags-filter--header--arrow')
    const list = document.getElementById('works-tags-filter--list')

    header.addEventListener('click', () => {
      arrow.classList.toggle('open')
      list.style.maxHeight = list.style.maxHeight ? null : `${list.scrollHeight}px`
    })
  }
})