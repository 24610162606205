document.addEventListener('turbolinks:load', () => {
  const officeRows = Array.from(document.getElementsByClassName('office-index--row'))
  
  officeRows.forEach(row => {
    const content = row.getElementsByClassName('office-content')[0]
    const toggle = row.getElementsByClassName('office-toggle')[0]
    const arrow = toggle.getElementsByClassName('arrow')[0]

    toggle.addEventListener('click', (event) => {
      arrow.classList.toggle('open')
      content.style.maxHeight = content.style.maxHeight ? null : `${content.scrollHeight}px`
    })
  })
})